import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h4>{`Other links`}</h4>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://algolia.fm"
        }}>{`Algolia Podcast`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://medium.com/@t2hnd"
        }}>{`Medium`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://qiita.com/t2hnd"
        }}>{`Qiita`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      